<template>
    <v-container>
        <base-material-card icon="mdi-account-network" title="Usuario" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Nombre" v-model="usuario.name" :error-messages="errors.name" class="required"></v-text-field>
                            <v-text-field label="Correo electrónico" v-model="usuario.email" :error-messages="errors.email" class="required"></v-text-field>
                            <v-select v-model="usuario.role_id" :items="roles" label="Role" item-value="id" item-text="nombre" :error-messages="errors.role_id" class="required"></v-select>
                            <v-text-field label="Contraseña" v-model="usuario.password" :error-messages="errors.password" class="required"></v-text-field>
                            <v-text-field label="Confirmar contraseña" v-model="usuario.password_confirmation" class="required"></v-text-field>
                            <v-radio-group v-model="usuario.enabled" :mandatory="true">
                                <v-radio label="Habilitado" :value="1"></v-radio>
                                <v-radio label="Deshabilitado" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="save" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$router.back()" :loading="loading">Cancelar</v-btn>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>

    export default {
        data: () => ({
            roles: [],
            usuario: {},
            adjunto: null,
            errors: {},
            loading: false
        }),
        mounted() {
            this.loadRoles()
            if (this.$route.params.id) {
                this.load()
            }
        },
        methods: {
            loadRoles() {
                this.$http.get('/roles/all').then(response => {
                    this.roles = response.data
                })
            },
            load() {
                this.$http.get(`/usuarios/${this.$route.params.id}`).then(response => {
                    this.usuario = response.data
                })
            },
            save() {
                this.errors = {}
                this.loading = true
                if (this.$route.params.id) {
                    this.update()
                    return
                }
                this.create()
            },
            create() {
                this.$http.post('/usuarios', this.usuario).then(response => {
                    this.$router.back()
                }).catch(error => {
                    console.log(error)
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            update() {
                this.$http.put(`/usuarios/${this.$route.params.id}`, this.usuario).then(response => {
                    this.$router.back()
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        }

    }
</script>